import Navigation from "../Navigation";
import {
    GovAccordionItem,
    GovBreadcrumbs, GovButton,
    GovFormControl, GovFormFile,
    GovFormInput,
    GovFormLabel, GovFormMessage, GovFormSelect,
    GovIcon, GovLoading, GovMessage,
    GovSkeleton, GovSpacer
} from "@gov-design-system-ce/react";
import {useCallback, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {convertDateToDDMMYYYY, convertIsoToLocalDate, getIcon, mapWorkAgreementType} from "../utils/utils";

export function ProfileEditContent({userId}) {
    const name = localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));


    const [currentEmployee, setCurrentEmployee] = useState(null);
    const [employeeNumber, setEmployeeNumber] = useState(user.employeeId);
    const [employeeAddress, setEmployeeAddress] = useState(null);
    const [employmentType, setEmploymentType] = useState("");
    const [employmentRate, setEmploymentRate] = useState(null);
    const [workContractFromDate, setWorkContractFromDate] = useState('');
    const [workContractToDate, setWorkContractToDate] = useState('');

    const [editFields, setEditFields] = useState(false);



    const [employeeNumberValid, setEmployeeNumberValid] = useState(false);
    const [employeeAddressValid, setEmployeeAddressValid] = useState(false);
    const [employmentTypeValid, setEmploymentTypeValid] = useState(false);
    const [employmentRateValid, setEmploymentRateValid] = useState(false);
    const [workContractFromDateValid, setWorkContractFromDateValid] = useState(false);
    const [workContractToDateValid, setWorkContractToDateValid] = useState(false);

    const [userValid, setUserValid] = useState(false);


    function fetchUser() {
        fetch(process.env.REACT_APP_API_URL + `/users/${userId ? userId : user.id}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then(jsonData => {
                console.log(jsonData)
            setUser(jsonData);
            setEmployeeNumber(jsonData.employeeId);
            setEmployeeAddress(jsonData.address);
            jsonData.workAgreementTypeId ? setEmploymentType(jsonData.workAgreementTypeId) : setEmploymentType("");
            setEmploymentRate(jsonData.workAllocation);
            jsonData.workFrom && setWorkContractFromDate(convertIsoToLocalDate(jsonData.workFrom));
            jsonData.workTo && setWorkContractToDate(convertIsoToLocalDate(jsonData.workTo));

            setEmployeeNumberValid(!!jsonData.employeeId);
            setEmployeeAddressValid(!!jsonData.address);
            setEmploymentTypeValid(!!jsonData.workAgreementTypeId);
            setEmploymentRateValid(!!jsonData.workAllocation);
            setWorkContractFromDateValid(!!jsonData.workFrom);
            setWorkContractToDateValid(!!jsonData.workTo);

        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

        fetch(process.env.REACT_APP_API_URL + `/users/${userId ? userId : user.id}/driversLicense/FRONT`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (response.status === 204) {
                    // Handle 204 No Content response
                    return null;
                }
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                if (jsonData) {
                    console.log(jsonData)
                    setDrivingLicenceFront(jsonData);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        fetch(process.env.REACT_APP_API_URL + `/users/${userId ? userId : user.id}/driversLicense/BACK`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (response.status === 204) {
                    // Handle 204 No Content response
                    return null;
                }
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                if (jsonData) {
                    console.log(jsonData)
                    setDrivingLicenceBack(jsonData);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    function checkErrors() {
        let ready = employeeAddressValid && employeeNumberValid && employmentRateValid && employmentTypeValid &&
            workContractFromDateValid && workContractToDateValid
        setUserValid(ready)
    }

    useEffect(() => {
        checkErrors()
    }, [employeeAddressValid,employeeNumberValid,employmentRateValid,employmentTypeValid, workContractFromDateValid, workContractToDateValid]);

    const handleEmployeeNumberChange = (event) => {
        if (isNumeric(event.target.value)) {
            setEmployeeNumberValid(true)
        } else {
            setEmployeeNumberValid(false)
        }
        setEmployeeNumber(event.target.value);
    };
    const handleEmployeeAddressChange = (event) => {
        if (event.target.value !== "") {
            setEmployeeAddressValid(true)
        } else {
            setEmployeeAddressValid(false)
        }
        setEmployeeAddress(event.target.value);
    };

    const handleEmploymentTypeChange = (event) => {
        if (event.target.value !== "") {
            setEmploymentTypeValid(true)
        } else {
            setEmploymentTypeValid(false)
        }
        setEmploymentType(event.target.value);
    };

    const handleEmploymentRateChange = (event) => {
        if (isNumeric(
            event.target.value
    )) {
            setEmploymentRateValid(true)
        } else {
            setEmploymentRateValid(false)
        }
        setEmploymentRate(event.target.value);
    };


    const handleWorkContractFromDateChange = (event) => {
        if (event.target.value !== "") {
            setWorkContractFromDateValid(true)
        }
        else {
            setWorkContractFromDateValid(false)
        }
        setWorkContractFromDate(event.target.value);
    };


    const handleWorkContractToDateChange = (event) => {
        if (event.target.value !== "") {
            setWorkContractToDateValid(true)
        }
        else {
            setWorkContractToDateValid(false)
        }
        setWorkContractToDate(event.target.value);
    };
    const jwt = localStorage.getItem("jwt")
    const [currentId, setCurrentId] = useState(localStorage.getItem("userId"));
    const [drivingLicenceFront, setDrivingLicenceFront] = useState(null);
    const [drivingLicenceBack, setDrivingLicenceBack] = useState(null);

    function patchEmployee(){
        fetch(process.env.REACT_APP_API_URL +`/users/${currentId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            method: 'PATCH',
            body: JSON.stringify({

                "employeeId": employeeNumber,
                "workAllocation": employmentRate,
                "address": employeeAddress,
                "workAgreementTypeId": employmentType,
                "firstname": user.firstname,
                "lastname": user.lastname,
                "role": user.role,
                "phone": user.phone,
                "regionalCenterId": user.regionalCenter.id,
                "loginEmail": user.loginEmail,
                "workFrom": workContractFromDate + "T00:00:00",
                "workTo":  workContractToDate + "T00:00:00"
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                console.log()
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });


    }
    const [documentIsLoading, setDocumentIsLoading] = useState(false);
    function uploadDocument(file, side) {
        setDocumentIsLoading(true)
        console.log("loading")
        const formData = new FormData();
        formData.append('file', file);


        fetch(`${process.env.REACT_APP_API_URL}/users/${userId ? userId : user.id}/driversLicense/${side}/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setDocumentIsLoading(false)
                console.log(jsonData);
                fetchUser();
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }

    function fetchDrivingLicenceDocument(side) {
        fetch(`${process.env.REACT_APP_API_URL}/users/${user.id}/driversLicense/${side}/download`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                // Check if the MIME type of the blob is a PDF or CSV
                if (blob.type === 'application/pdf') {
                    // Open the file in a new tab
                    window.open(url);
                } else {
                    // Download the file
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    if(side === "FRONT"){
                        a.download = drivingLicenceFront.name;
                    }
                    else {
                        a.download = drivingLicenceBack.name;
                    }

                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            })

    }

    const renderFieldInfo = (fieldName, fieldValue) => {
        return (
            <div>
                <h4>{fieldName}</h4>
                <p>{fieldValue ? fieldValue : "Informace není uvedena"}</p>
            </div>
        );
    };


    useEffect(() => {
        fetchUser()
    }, [])

    let debounceTimeout;
    const debouncedUploadDocument = useCallback((file, type) => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => uploadDocument(file, type), 300);
    }, []);

    return (
        <div>
            <Navigation />
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/settings">Nastavení</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Změna osobních údajů {userId && "(" + user.firstname + " " + user.lastname + ")"}</strong></li>
                    </ul>
                </GovBreadcrumbs>

                <div className="gov-container">

                    <div className="gov-container__content vh-80">


                    <div className="gov-title--beta">Změna osobních údajů {userId && "(" + user.firstname + " " + user.lastname + ")"}</div>
                        {userId && userId !== localStorage.getItem("userId") &&
                            <GovMessage className="mb-20" slot="bottom" variant="error"><p>Upozornění: změna osobních údajů jiného uživatele</p>
                                <GovIcon type="basic" name="exclamation-triangle-fill"
                                         slot="icon"></GovIcon>
                            </GovMessage>
                        }

                        <div className="form-help-layout gov-tabs">
                            <div className="gov-grid--x2 gov-grid">
                                {editFields ?
                                <div>
                                    <form method="post">
                                        <GovFormControl>
                                            <GovFormInput value={employeeNumber}
                                                          onGov-input={handleEmployeeNumberChange}
                                                          size="s" variant="secondary"
                                                          input-type="number"
                                                          placeholder="Číslo zaměstnance"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Číslo zaměstnance*</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput value={employeeAddress}
                                                          onGov-input={handleEmployeeAddressChange}
                                                          size="s" variant="secondary"
                                                          input-type="text"
                                                          placeholder="Adresa trvalého bydliště"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Adresa trvalého
                                                bydliště*</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormSelect size="s" variant="secondary" value={employmentType}
                                                           onGov-change={handleEmploymentTypeChange}>
                                                <option disabled="disabled" value="">Typ pracovního poměru</option>
                                                <option value="1">HPP</option>
                                                <option value="2">DPČ</option>
                                            </GovFormSelect>
                                            <GovFormLabel slot="top" size="s">Typ pracovního poměru*</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput value={employmentRate}
                                                          onGov-input={handleEmploymentRateChange}
                                                          size="s" variant="secondary"
                                                          input-type="number"
                                                          placeholder="Výše úvazku"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Výše úvazku*</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormLabel slot="top" size="s">Smlouva na dobu od</GovFormLabel>
                                            <GovFormInput
                                                size="s" variant="secondary"
                                                input-type="date"
                                                value={workContractFromDate}
                                                onGov-input={handleWorkContractFromDateChange}>
                                            </GovFormInput>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormLabel slot="top" size="s">Smlouva na dobu do</GovFormLabel>
                                            <GovFormInput
                                                size="s" variant="secondary"
                                                input-type="date"
                                                value={workContractToDate}
                                                onGov-input={handleWorkContractToDateChange}>
                                            </GovFormInput>
                                        </GovFormControl>
                                        {!userValid &&
                                            <GovMessage variant="error"><p>Nejsou vyplněny všechny povinné
                                                položky</p>
                                            </GovMessage>}
                                        <GovButton className={"send-button"} onGov-click={() => {
                                            patchEmployee()
                                            setEditFields(false)
                                        }}
                                                   wcag-label="What exactly happens when you click?"
                                                   variant="primary" disabled={!userValid} type="solid" size="m">Uložit
                                        </GovButton>
                                        <GovButton className="ml-5" onGov-click={() => setEditFields(false)}
                                                   wcag-label="What exactly happens when you click?"
                                                   variant="error" type="link" size="m">Zrušit
                                        </GovButton>


                                    </form>
                                </div>
                                    :
                                    <div>
                                        {renderFieldInfo("Číslo zaměstnance", employeeNumber)}
                                        {renderFieldInfo("Adresa trvalého bydliště", employeeAddress)}
                                        {renderFieldInfo("Typ pracovního poměru", mapWorkAgreementType(employmentType))}
                                        {renderFieldInfo("Výše úvazku", employmentRate)}
                                        {renderFieldInfo("Smlouva na dobu od", (convertDateToDDMMYYYY(workContractFromDate)))}
                                        {renderFieldInfo("Smlouva na dobu do", (convertDateToDDMMYYYY(workContractToDate)))}
                                        <GovButton onGov-click={() => setEditFields(true)}
                                                   wcag-label="What exactly happens when you click?"
                                                   variant="primary" type="link" size="m">Upravit
                                        </GovButton>
                                    </div>
                                }
                                <div className="ml-30">
                                    {!drivingLicenceFront ?
                                        <GovFormControl>
                                            <GovFormLabel slot="top" size="s">Řidičský průkaz - přední stránka</GovFormLabel>
                                            <GovFormFile
                                                onGov-add-file={(event) => debouncedUploadDocument(event.detail.file.file, "FRONT")}
                                            max-file-size="616448"
                                            accept=".pdf,.doc,.docx"
                                            expanded="true"
                                            displayAttachments="false">
                                            <p>
                                                <GovButton variant="primary" size="m"
                                                           type="outlined">Nahrát soubor ze
                                                    zařízení
                                                </GovButton>
                                            </p>
                                            <GovSpacer size="m"></GovSpacer>
                                            <p className="gov-text--s">Podporované formáty
                                                PDF,
                                                DOC</p></GovFormFile>
                                    </GovFormControl>
                                    :
                                        <div>
                                            <h3>Řidičský průkaz - přední strana</h3>
                                            <FontAwesomeIcon icon={getIcon(drivingLicenceFront.name)}/>
                                            <span> </span>
                                            <a href={drivingLicenceFront.link} onClick={(event) => {
                                                event.preventDefault();
                                                fetchDrivingLicenceDocument("FRONT");
                                            }}>
                                                {drivingLicenceFront.name}
                                            </a>
                                            <GovAccordionItem className="mt-10" variant="primary" size="xs">
                                                <GovFormControl>
                                                    <GovFormFile
                                                        onGov-add-file={(event) => debouncedUploadDocument(event.detail.file.file, "FRONT")}
                                                        max-file-size="616448"
                                                        accept=".pdf,.doc,.docx"
                                                        expanded="true"
                                                        displayAttachments="false">
                                                        <p>
                                                            <GovButton variant="primary" size="m"
                                                                       type="outlined">Nahrát soubor ze
                                                                zařízení
                                                            </GovButton>
                                                        </p>
                                                        <GovSpacer size="m"></GovSpacer>
                                                        <p className="gov-text--s">Podporované formáty
                                                            PDF,
                                                            DOC</p></GovFormFile>
                                                </GovFormControl>
                                                <p slot="label">Nahrát jinou přední stranu</p>
                                            </GovAccordionItem>
                                        </div>

                                    }
                                    {!drivingLicenceBack ?
                                        <GovFormControl className="mt-10">
                                            <GovFormLabel slot="top" size="s">Řidičský průkaz - zadní strana</GovFormLabel>
                                            <GovFormFile
                                                onGov-add-file={(event) => debouncedUploadDocument(event.detail.file.file, "BACK")}
                                                max-file-size="616448"
                                                accept=".pdf,.doc,.docx"
                                                expanded="true"
                                                displayAttachments="false">
                                                <p>
                                                    <GovButton variant="primary" size="m"
                                                               type="outlined">Nahrát soubor ze
                                                        zařízení
                                                    </GovButton>
                                                </p>
                                                <GovSpacer size="m"></GovSpacer>
                                                <p className="gov-text--s">Podporované formáty
                                                    PDF,
                                                    DOC</p></GovFormFile>
                                        </GovFormControl>
                                        :
                                        <div  className="mt-10">
                                            <h3>Řidičský průkaz - zadní strana</h3>
                                            <FontAwesomeIcon icon={getIcon(drivingLicenceBack.name)}/>
                                            <span> </span>
                                            <a href={drivingLicenceBack.link} onClick={(event) => {
                                                event.preventDefault();
                                                fetchDrivingLicenceDocument("BACK");
                                            }}>
                                                {drivingLicenceBack.name}
                                            </a>
                                            <GovAccordionItem className="mt-10" variant="primary" size="xs">
                                                <GovFormControl>
                                                    <GovFormFile
                                                        onGov-add-file={(event) => debouncedUploadDocument(event.detail.file.file, "BACK")}
                                                        max-file-size="616448"
                                                        accept=".pdf,.doc,.docx"
                                                        expanded="true"
                                                        displayAttachments="false">
                                                        <p>
                                                            <GovButton variant="primary" size="m"
                                                                       type="outlined">Nahrát soubor ze
                                                                zařízení
                                                            </GovButton>
                                                        </p>
                                                        <GovSpacer size="m"></GovSpacer>
                                                        <p className="gov-text--s">Podporované formáty
                                                            PDF,
                                                            DOC</p></GovFormFile>
                                                </GovFormControl>
                                                <p slot="label">Nahrát jinou zadní stranu</p>
                                            </GovAccordionItem>
                                        </div>

                                    }
                                </div>

                            </div>

                        </div>
                        <div>
                            {documentIsLoading &&
                                <div>
                                    <GovLoading>Prosím vyčkejte soubor se nahrává</GovLoading>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
