import {
    GovButton,
    GovFormCheckbox,
    GovFormControl, GovFormGroup,
    GovFormInput,
    GovFormLabel,
    GovFormSelect, GovIcon, GovMessage, GovModal,
    GovSkeleton
} from "@gov-design-system-ce/react";
import Select, {components} from "react-select";
import {MenuList} from "./MenuList";
import {useEffect, useState} from "react";
import {convertIsoToLocalDate, convertIsoToTime, parseJwt} from "../utils/utils";
import {useNavigate} from "react-router-dom";

export function TimesheetModal(props) {

    const jwt = localStorage.getItem("jwt")

    const [modalTimesheetOpen, setModalTimesheetOpen] = useState(true);
    const [timesheetType, setTimesheetType] = useState("1");
    const [preProjectConsultations, setPreProjectConsultations] = useState([])
    const [projects, setProjects] = useState([])
    const [villages, setVillages] = useState([])
    const [selectedPreProjectConsultationId, setSelectedPreProjectConsultationId] = useState('')
    const [selectedProjectId, setSelectedProjectId] = useState('')
    const [selectedVillageId, setSelectedVillageId] = useState('')
    const [moreDaysVacation, setMoreDaysVacation] = useState(false)
    const [timesheetDate, setTimesheetDate] = useState(new Date().toISOString().split('T')[0]);
    const [vacationDateTo, setVacationDateTo] = useState('')
    const [timesheetHoursWorked, setTimesheetHoursWorked] = useState(null)
    const [timesheetStartTime, setTimesheetStartTime] = useState('')
    const [timesheetEndTime, setTimesheetEndTime] = useState('')
    const [timesheetDescription, setTimesheetDescription] = useState('')
    const [activityCollision, setActivityCollision] = useState(false)
    const [timesheetIsTrip, setTimesheetIsTrip] = useState(false);
    const [dateFromFilter, setDateFromFilter] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    const [dateToFilter, setDateToFilter] = useState(new Date())

    const [timesheetTypeInvalid, setTimesheetTypeInvalid] = useState(false)
    const [selectedProjectIdInvalid, setSelectedProjectIdInvalid] = useState(true)
    const [selectedVillageIdInvalid, setSelectedVillageIdInvalid] = useState(true)
    const [selectedPreProjectConsultationIdInvalid, setSelectedPreProjectConsultationIdInvalid] = useState(true)
    const [timesheetDateInvalid, setTimesheetDateInvalid] = useState(false)
    const [vacationDateToInvalid, setVacationDateToInvalid] = useState(true)
    const [timesheetTimesRangeInvalid, setTimesheetTimesRangeInvalid] = useState(true)
    const [timesheetStartTimeInvalid, setTimesheetStartTimeInvalid] = useState(true)
    const [timesheetEndTimeInvalid, setTimesheetEndTimeInvalid] = useState(true)
    const [timesheetDescriptionInvalid, setTimesheetDescriptionInvalid] = useState(true)
    const [vacationValid, setVacationValid] = useState(false)
    const [timesheetValid, setTimesheetValid] = useState(false)

    const handleTimesheetTypeChange = (event) => {
        // commented out for faster usage
        // setTimesheetHoursWorked(null)
        // setTimesheetStartTime('')
        // setTimesheetEndTime('')
        // setTimesheetDate('2024-01-01')
        // setTimesheetStartTimeInvalid(true)
        // setTimesheetEndTimeInvalid(true)
        // setTimesheetDateInvalid(true)
        setMoreDaysVacation(false)
        setSelectedProjectId('')
        setSelectedVillageId('')
        if (event.target.value === "") {
            setTimesheetTypeInvalid(true)
        } else {
            setTimesheetTypeInvalid(false)
        }
        setTimesheetType(event.target.value);
    }

    function calculateHoursWorkedForDateChange(date, startTime, endTime) {
        const start = new Date(`${date}T${startTime}:00`);
        const end = new Date(`${date}T${endTime}:00`);
        const result = (end - start) / (1000 * 60 * 60)

        if (isNaN(result) || result <= 0) {
            setTimesheetTimesRangeInvalid(true)
            return 0
        } else {
            const decimalPart = result % 1;
            const validEndings = [0.00, 0.50];

            if (!validEndings.includes(decimalPart)) {
                setTimesheetTimesRangeInvalid(true);
                return result.toFixed(2);
            } else {
                setTimesheetTimesRangeInvalid(false);
                return result.toFixed(2);
            }
        }
    }

    const handleDateChange = (event) => {
        if (event.target.value === "") {
            setTimesheetDateInvalid(true)
        } else {
            setTimesheetDateInvalid(false)
        }
        setTimesheetDate(event.target.value);
        setTimesheetHoursWorked(calculateHoursWorkedForDateChange(event.target.value, timesheetStartTime, timesheetEndTime));
    };

    function calculateHoursWorked(startTime, endTime) {
        const start = new Date(`${timesheetDate}T${startTime}:00`);
        const end = new Date(`${timesheetDate}T${endTime}:00`);
        const result = (end - start) / (1000 * 60 * 60)

        if (isNaN(result) || result <= 0) {
            setTimesheetTimesRangeInvalid(true)
            return 0
        } else {
            const decimalPart = result % 1;
            const validEndings = [0.00, 0.50];

            if (!validEndings.includes(decimalPart)) {
                setTimesheetTimesRangeInvalid(true);
                return result.toFixed(2);
            } else {
                setTimesheetTimesRangeInvalid(false);
                return result.toFixed(2);
            }
        }
    }


    const handleStartTimeChange = (event) => {
        if (event.target.value === "") {
            setTimesheetStartTimeInvalid(true)
        } else {
            setTimesheetStartTimeInvalid(false)
        }
        setTimesheetStartTime(event.target.value);
        setTimesheetHoursWorked(calculateHoursWorked(event.target.value, timesheetEndTime));
    }

    const handleEndTimeChange = (event) => {
        if (event.target.value === "") {
            setTimesheetEndTimeInvalid(true)
        } else {
            setTimesheetEndTimeInvalid(false)
        }
        setTimesheetEndTime(event.target.value);
        setTimesheetHoursWorked(calculateHoursWorked(timesheetStartTime, event.target.value));
    }

    const handleDescriptionChange = (event) => {
        if (event.target.value === "") {
            setTimesheetDescriptionInvalid(true)
        } else {
            setTimesheetDescriptionInvalid(false)
        }
        setTimesheetDescription(event.target.value);
    }

    const handleVacationDateToChange = (event) => {
        if (event.target.value === "") {
            setVacationDateToInvalid(true)
        } else {
            setVacationDateToInvalid(false)
        }
        setVacationDateTo(event.target.value);
    }

    const handleVillageIdChange = (event) => {
        if (event.target === "") {
            setSelectedVillageIdInvalid(true)
        } else {
            setSelectedVillageIdInvalid(false)
        }
        setSelectedVillageId(event.value);
    };

    const handlePreProjectConsultationChange = (event) => {
        if (event.target === "") {
            setSelectedPreProjectConsultationIdInvalid(true)
        } else {
            setSelectedPreProjectConsultationIdInvalid(false)
        }
        setSelectedPreProjectConsultationId(event.value);
        setTimesheetStartTime(convertIsoToTime(event.start));
        setTimesheetEndTime(convertIsoToTime(event.end));
        setTimesheetDate(convertIsoToLocalDate(event.start));
        setTimesheetStartTimeInvalid(false)
        setTimesheetEndTimeInvalid(false)
        setTimesheetDateInvalid(false)
        setTimesheetHoursWorked(calculateHoursWorkedForDateChange(convertIsoToLocalDate(event.start), convertIsoToTime(event.start), convertIsoToTime(event.end)));
    };

    const handleMoreDaysVacationChange = (event) => {
        setMoreDaysVacation(!moreDaysVacation);
        setTimesheetStartTime('');
        setTimesheetEndTime('');
    };

    const handleProjectIdChange = (event) => {
        if (event.value === "") {
            setSelectedProjectIdInvalid(true)
        } else {
            setSelectedProjectIdInvalid(false)
        }
        setSelectedProjectId(event.value);
    };
    let user = props.user ? props.user : JSON.parse(localStorage.getItem("user"));
    const [userDetail, setUserDetail] = useState(null);
    const [userDetailsInvalid, setUserDetailsInvalid] = useState(false);


    useEffect(() => {
        fetchUser()
    }, [])

    function fetchUser() {
        fetch(process.env.REACT_APP_API_URL + `/users/${user.id}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then(jsonData => {
            setUserDetailsInvalid(
                jsonData.address === null ||
                jsonData.employeeId === null ||
                jsonData.workAgreementTypeId === null ||
                jsonData.workAllocation === null ||
                jsonData.workFrom === null ||
                jsonData.workTo === null
            );
            setUserDetail(jsonData)
        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
    const fetchPreProjectsConsultations = () => {
        if (authority !== "HEADQUARTERS") {
            request = "?rcId=" + RCid;
        } else {
            request = "";
        }
        fetch(process.env.REACT_APP_API_URL + "/preProjectConsultations" + request, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                console.log(jsonData)
                const preProjectConsultationsArray = jsonData.map(preProjectConsultation => {
                    return {value: preProjectConsultation.id, label: preProjectConsultation.town.name + " (" + preProjectConsultation.id + ")", start: preProjectConsultation.start, end: preProjectConsultation.end}
                })
                console.log(preProjectConsultationsArray)
                setPreProjectConsultations(preProjectConsultationsArray);

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const fetchProjects = () => {
        if (authority !== "HEADQUARTERS") {
            request = "?regionalCenterId=" + RCid;
        } else {
            request = "";
        }
        fetch(process.env.REACT_APP_API_URL + "/projects" + request, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                const projectsArray = jsonData.map(project => {
                    return {value: project.id, label: project.name + " (" + project.id + ")"}
                })
                setProjects(projectsArray);

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;
    const RCid = localStorage.getItem("RCid");
    let request;
    const fetchVillages = () => {
        if (authority === "HEADQUARTERS") {
            request = "/towns";
        } else {
            request = "/rc/" + RCid + "/towns";
        }
        if (localStorage.getItem("towns") === null) {
            fetch(process.env.REACT_APP_API_URL + request, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(jsonData => {
                    setVillages(jsonData);
                    localStorage.setItem("towns", JSON.stringify(jsonData))
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } else {
            setVillages(JSON.parse(localStorage.getItem("towns")))
        }
    };


    useEffect(() => {
        if (timesheetType === "2" || timesheetType === 2) {
            fetchVillages();
        } else if (timesheetType === "3" || timesheetType === 3) {
            fetchProjects();
        }
        else if (timesheetType === "7" || timesheetType === 7) {
            fetchPreProjectsConsultations();
        }
    }, [timesheetType]);


    const DropdownIndicator = (
        props
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <GovIcon type="basic" name="chevron-down"></GovIcon>
            </components.DropdownIndicator>
        );
    };
    const customStyles = {
        dropdownIndicator: (styles, state) => ({
            ...styles,
            width: "28px",
            marginRight: "5px",
            "&:hover": {
                color: "#686868"
            },
            color: state.isFocused ? "#686868" : "#686868",
            fill: "red"
        }),
        option: (styles, state) => ({
            ...styles,
            cursor: 'pointer',
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            cursor: 'pointer',
            borderRadius: '3px',
            "&:hover": {
                borderColor: "#2362a2"
            },
            borderColor: '#686868',
            minHeight: '32px',
            height: '32px',
            boxShadow: state.isFocused ? null : null,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            color: '#686868',
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            fontSize: '14px',
            padding: '0 8px',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            color: '#686868',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#686868',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '32px',
        }),
    };

    function postMoreDaysVacation() {
        setModalTimesheetOpen(false)
        fetch(process.env.REACT_APP_API_URL + "/workReports/range", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "type": timesheetType,
                "start": timesheetDate,
                "end": vacationDateTo,
                "description": timesheetDescription
            })
        })
            .then(response => {
                if (response.status === 409) {
                    alert("Chyba: Dovolená koliduje s timesheetem nebo jinou dovolenou, případně byl překročen pracovní fond pro tento měsíc.");
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                props.onGovModalClose()
                setModalTimesheetOpen(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .catch(error => {
                return error.message;
            });
    }

    function putWorkReport() {
        setModalTimesheetOpen(false)
        fetch(process.env.REACT_APP_API_URL + `/workReports/${props.timesheetId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                "type": timesheetType,
                "start": timesheetDate + "T" + timesheetStartTime + ":00",
                "end": timesheetDate + "T" + timesheetEndTime + ":00",
                "isTrip": timesheetIsTrip,
                "description": timesheetDescription,
                "projectId": selectedProjectId === "" ? null : selectedProjectId,
                "townId": selectedVillageId === "" ? null : selectedVillageId,
                "preProjectConsultationId": selectedPreProjectConsultationId === "" ? null : selectedPreProjectConsultationId
            })
        })
            .then(response => {
                if (response.status === 409) {
                    alert("Chyba: Timesheet koliduje s jiným timesheetem nebo cestovní náhradou, případně byl překročen pracovní fond pro tento měsíc.");
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                props.onGovModalClose()
                setModalTimesheetOpen(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    function postWorkReport() {
        setModalTimesheetOpen(false)
        fetch(process.env.REACT_APP_API_URL + "/workReports", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "type": timesheetType,
                "start": timesheetDate + "T" + timesheetStartTime + ":00",
                "end": timesheetDate + "T" + timesheetEndTime + ":00",
                "description": timesheetDescription,
                "projectId": selectedProjectId === "" ? null : selectedProjectId,
                "townId": selectedVillageId === "" ? null : selectedVillageId,
                "preProjectConsultationId": selectedPreProjectConsultationId === "" ? null : selectedPreProjectConsultationId,
            })
        })
            .then(response => {
                if (response.status === 409) {
                    alert("Chyba: Timesheet koliduje s jiným timesheetem nebo cestovní náhradou, případně byl překročen pracovní fond pro tento měsíc.");
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                props.onGovModalClose()
                setModalTimesheetOpen(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .catch(error => {
                return error.message;
            });
    }

    function calculateHoursWorkedForEdit(startTime, endTime) {
        const start = new Date(`${startTime}`);
        const end = new Date(`${endTime}`);
        const result = (end - start) / (1000 * 60 * 60)
        if (isNaN(result) || result <= 0) {
            setTimesheetTimesRangeInvalid(true)
            return 0
        } else {
            const decimalPart = result % 1;
            const validEndings = [0.00, 0.50];

            if (!validEndings.includes(decimalPart)) {
                setTimesheetTimesRangeInvalid(true);
                return result.toFixed(2);
            } else {
                setTimesheetTimesRangeInvalid(false);
                return result.toFixed(2);
            }
        }
    }


    const fetchCurrentTimesheet = (id) => {
        let timesheet
        fetch(process.env.REACT_APP_API_URL + `/workReports/${id}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then(jsonData => {
            timesheet = jsonData;

            setTimesheetDate(convertIsoToLocalDate(timesheet.start))
            setTimesheetStartTime(convertIsoToTime(timesheet.start))
            setTimesheetEndTime(convertIsoToTime(timesheet.end))
            setTimesheetHoursWorked(calculateHoursWorkedForEdit(timesheet.start, timesheet.end))
            setTimesheetType(timesheet.type.toString())
            setTimesheetIsTrip(timesheet.isTrip)

            setTimesheetDescription(timesheet.description)

            timesheet.project ? setSelectedProjectId(timesheet.project.id) : setSelectedProjectId('')
            timesheet.town ? setSelectedVillageId(timesheet.town.id) : setSelectedVillageId('')
            timesheet.preProjectConsultationId ? setSelectedPreProjectConsultationId(timesheet.preProjectConsultationId) : setSelectedPreProjectConsultationId('')
            setModalTimesheetOpen(true)
            setSelectedProjectIdInvalid(false)
            setSelectedVillageIdInvalid(false)
            setSelectedPreProjectConsultationIdInvalid(false)
            setTimesheetDateInvalid(false)
            setTimesheetStartTimeInvalid(false)
            setTimesheetEndTimeInvalid(false)
            setTimesheetDescriptionInvalid(false)
            setTimesheetTimesRangeInvalid(false)

        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        if (!props.create) {
            fetchCurrentTimesheet(props.timesheetId)
        }
    }, []);

    function checkErrorsVacations() {
        let ready
        if (moreDaysVacation) {
            ready = !timesheetTypeInvalid && !timesheetDateInvalid && !vacationDateToInvalid
                && !vacationDateToInvalid && (vacationDateTo > timesheetDate)
        } else {
            const startTime = new Date(`${timesheetDate}T${timesheetStartTime}:00`);
            const endTime = new Date(`${timesheetDate}T${timesheetEndTime}:00`);
            const timeDifferenceInMinutes = (endTime - startTime) / (1000 * 60);

            const validEndings = [0, 30];
            const isValidDifference = timeDifferenceInMinutes >= 15 && validEndings.includes(timeDifferenceInMinutes % 60);

            ready = !timesheetTypeInvalid && !timesheetDateInvalid
                && !timesheetStartTimeInvalid && !timesheetEndTimeInvalid
                && (timesheetEndTime > timesheetStartTime)
                && isValidDifference;
        }
        setVacationValid(ready)
    }

    const [filtersByTimesheetType, setFiltersByTimesheetType] = useState({
        "-1": true,
        "1": true,
        "2": true,
        "3": true,
        "4": true,
        "5": true,
        "6": true
    });

    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 100;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const [filteredItems, setFilteredItems] = useState(null);
    const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : null;

    let [timesheets, setTimesheets] = useState(null)

    const filterItems = () => {
        if (timesheets) {
            if (!dateFromFilter && !dateToFilter) {
                setFilteredItems([])
                return
            }
            let filtered = timesheets;

            // Filter by date range
            if (dateFromFilter || dateToFilter) {
                const fromDate = new Date(dateFromFilter);
                fromDate.setHours(0, 0, 0, 0);
                let toDate = new Date(dateToFilter);
                toDate.setHours(23, 59, 59, 999);

                if (dateFromFilter && dateToFilter) {
                    filtered = timesheets.filter(item => {
                        const startDate = new Date(item.start);
                        return startDate >= fromDate && startDate <= toDate;
                    });
                } else if (dateFromFilter) {
                    filtered = timesheets.filter(item => {
                        const startDate = new Date(item.start);
                        return startDate >= fromDate;
                    });
                }
            }

            // Filter by type
            filtered = filtered.filter(item => filtersByTimesheetType[item.type.toString()]);

            setFilteredItems(filtered);
        }
    };

    const filterItemsByType = () => {
        if (timesheets) {
            const filtered = timesheets.filter(item => filtersByTimesheetType[item.type.toString()]);

            setFilteredItems(filtered);
        }
    };

    useEffect(() => {
        filterItems();
    }, [timesheets, dateFromFilter, dateToFilter, filtersByTimesheetType]);


    function checkErrorsTimesheet() {
        const formStartTime = new Date(`${timesheetDate}T${timesheetStartTime}:00`);
        const formEndTime = new Date(`${timesheetDate}T${timesheetEndTime}:00`);
        let hasMatchingActivity
        if (props.timesheets) {
            hasMatchingActivity = props.timesheets.some(item => {
                const itemStartTime = new Date(item.start);
                const itemEndTime = new Date(item.end);
                return item.id !== props.timesheetId &&
                        (itemEndTime > formStartTime && itemStartTime < formEndTime);
            });
        }
        if (hasMatchingActivity) {
            setActivityCollision(true);
        } else {
            setActivityCollision(false);
        }
        let ready = !timesheetTypeInvalid && !timesheetDateInvalid
            && !timesheetStartTimeInvalid && !timesheetEndTimeInvalid && !timesheetDescriptionInvalid
            && !timesheetTimesRangeInvalid && !hasMatchingActivity && !userDetailsInvalid
        console.log(ready)
        if (timesheetType === "2") {
            ready = ready && !selectedVillageIdInvalid
        } else if (timesheetType === "3") {
            ready = ready && !selectedProjectIdInvalid
        } else if (timesheetType === "7") {
            ready = ready && !selectedPreProjectConsultationIdInvalid
        }
        console.log(timesheetTypeInvalid, timesheetDateInvalid, timesheetStartTimeInvalid, timesheetEndTimeInvalid, timesheetDescriptionInvalid, timesheetTimesRangeInvalid, hasMatchingActivity, userDetailsInvalid)
        console.log(ready)
        setTimesheetValid(ready)
    }
    const navigate = useNavigate();
    function goToUserDetailPage() {
        navigate("/profile/edit")
    }
    useEffect(() => {
        checkErrorsTimesheet()
    }, [timesheetType, timesheetDate, timesheetStartTime, timesheetEndTime, timesheetDescription, selectedProjectId, selectedVillageId, selectedPreProjectConsultationId])


    useEffect(() => {
        checkErrorsVacations()
    }, [timesheetType, moreDaysVacation, timesheetDate, timesheetStartTime, timesheetEndTime, vacationDateTo])

    useEffect(() => {
        return () => {
            document.body.classList.remove('body-fixed');
        };
    }, []);


    return (
        <GovModal open={modalTimesheetOpen} onGov-close={props.onGovModalClose}
                  label={props.create ? "Vyplnění nového timesheetu" : "Úprava timesheetu"}
                  wcag-close-label="Close dialog box with more information">
            <GovFormControl>
                <GovFormSelect id="dynamic-select" value={timesheetType}
                               onGov-change={handleTimesheetTypeChange}
                               size="s" variant="secondary">
                    <option value="1">Administrativa</option>
                    <option value="2">Obec</option>
                    <option value="3">Projekt</option>
                    <option value="7">Konzultace</option>
                    <option value="4">Konzultace MMR</option>
                    <option value="5">Školení</option>
                    <option value="-1">Dovolená</option>
                </GovFormSelect>
                <GovFormLabel slot="top" size="m">Vyberte typ výkazu</GovFormLabel>
            </GovFormControl>
            <div>
                {(timesheetType === "3" || timesheetType === 3) &&
                    <GovFormControl>
                        <div>
                            {projects.length !== 0 ?
                                <Select options={projects} onChange={handleProjectIdChange}
                                        placeholder={"Id projektu"}
                                        value={projects.find(project => project.value === selectedProjectId)}
                                        components={{DropdownIndicator}}
                                        styles={customStyles}
                                        noOptionsMessage={() => "Nenašli jsme projekty"}

                                />
                                // <Select options={projects} onChange={handleProjectIdChange}
                                //         components={{ MenuList, DropdownIndicator }}
                                //         placeholder={"Id projektu"}
                                //         styles={customStyles}
                                //         noOptionsMessage={() => "Nenašli jsme projekty"}
                                // />
                                : <GovSkeleton wcag-label="Loading content of page" variant="secondary"
                                               shape="text"
                                               animation="progress" count="1" width="300px"
                                               height="30px"></GovSkeleton>
                            }
                        </div>
                        <GovFormLabel slot="top" size="m">Vyberte ID projektu, ke které se výkaz
                            vztahuje</GovFormLabel>
                    </GovFormControl>
                }
            </div>
            <div>
                {(timesheetType === "2" || timesheetType === 2) &&

                    <GovFormControl>
                        <div>
                            {villages.length !== 0 ?
                                <Select components={{MenuList, DropdownIndicator}}
                                        options={villages.map(village => {
                                            return {
                                                value: village.id,
                                                label: village.name + " (" + village.id + ")"
                                            }
                                        })} onChange={handleVillageIdChange}
                                        placeholder={"Id obce"}
                                        value={villages.map(village => {
                                            return {
                                                value: village.id,
                                                label: village.name + " (" + village.id + ")"
                                            }
                                        }).find(village => village.value === selectedVillageId)}
                                        styles={customStyles}
                                        noOptionsMessage={() => "Nenašli jsme žádné obce"}/>
                                // <Select options={villages.map(village => {
                                //     return {value: village.id, label: village.name + " (" + village.id + ")"}
                                // })} onChange={handleVillageIdChange}
                                //         placeholder={"Id obce"}
                                //         value={villages.map(village => {
                                //             return {value: village.id, label: village.name + " (" + village.id + ")"}
                                //         }).find(village => village.value === selectedVillageId)}
                                //         components={{DropdownIndicator}}
                                //         styles={customStyles}
                                //         noOptionsMessage={() => "Nenašli jsme žádné obce"}
                                //
                                // />
                                : <GovSkeleton wcag-label="Loading content of page" variant="secondary"
                                               shape="text"
                                               animation="progress" count="1" width="300px"
                                               height="30px"></GovSkeleton>
                            }
                        </div>
                        <GovFormLabel slot="top" size="m">Vyberte ID obce, ke které se výkaz
                            vztahuje</GovFormLabel>
                    </GovFormControl>
                }
            </div>
            {console.log(preProjectConsultations.find(preProjectConsultation => preProjectConsultation.value === selectedPreProjectConsultationId))}
            <div>
                {(timesheetType === "7" || timesheetType === 7) &&
                    <GovFormControl>
                        <div>
                            {preProjectConsultations.length !== 0 ?
                                <Select options={preProjectConsultations} onChange={handlePreProjectConsultationChange}
                                        placeholder={"Id konzultace"}
                                        value={preProjectConsultations.find(preProjectConsultation => preProjectConsultation.value === selectedPreProjectConsultationId)}
                                        components={{DropdownIndicator}}
                                        styles={customStyles}
                                        noOptionsMessage={() => "Nenašli jsme konzultace"}

                                />
                                // <Select options={villages.map(village => {
                                //     return {value: village.id, label: village.name + " (" + village.id + ")"}
                                // })} onChange={handleVillageIdChange}
                                //         placeholder={"Id obce"}
                                //         value={villages.map(village => {
                                //             return {value: village.id, label: village.name + " (" + village.id + ")"}
                                //         }).find(village => village.value === selectedVillageId)}
                                //         components={{DropdownIndicator}}
                                //         styles={customStyles}
                                //         noOptionsMessage={() => "Nenašli jsme žádné obce"}
                                //
                                // />
                                : <GovSkeleton wcag-label="Loading content of page" variant="secondary"
                                               shape="text"
                                               animation="progress" count="1" width="300px"
                                               height="30px"></GovSkeleton>
                            }
                        </div>
                        <GovFormLabel slot="top" size="m">Vyberte ID konzultace, ke které se výkaz
                            vztahuje</GovFormLabel>
                    </GovFormControl>
                }
            </div>
            <div>
                {timesheetType === "-1" && props.create &&
                    <GovFormControl>
                        <GovFormCheckbox checked={moreDaysVacation} size="s"
                                         onGov-change={handleMoreDaysVacationChange}>
                            <GovFormLabel size="s" slot="label">Vícedenní dovolená</GovFormLabel>
                        </GovFormCheckbox>
                    </GovFormControl>
                }
            </div>
            <GovFormControl>
                <GovFormLabel slot="top" size="s">{moreDaysVacation ? "Datum od" : "Datum"}</GovFormLabel>
                <GovFormInput
                    size="s" variant="secondary"
                    input-type="date"
                    value={timesheetDate}
                    min="2024-01-01"
                    max={new Date().toISOString().split('T')[0]}
                    onGov-input={handleDateChange}>
                </GovFormInput>
            </GovFormControl>
            <div>
                {timesheetType === "-1" && moreDaysVacation &&
                    <GovFormControl>
                        <GovFormLabel slot="top" size="s">Datum do</GovFormLabel>
                        <GovFormInput
                            size="s" variant="secondary"
                            input-type="date"
                            value={vacationDateTo}
                            min="2024-01-01"
                            max={new Date().toISOString().split('T')[0]}
                            onGov-input={handleVacationDateToChange}>
                        </GovFormInput>
                    </GovFormControl>
                }
            </div>
            <div>
                {!moreDaysVacation &&
                    <div>
                        <GovFormControl>
                            <GovFormLabel slot="top"
                                          size="s">{timesheetType === "-1" ? "Začátek dovolené" : "Začátek aktivity"}</GovFormLabel>
                            <GovFormInput
                                size="s" variant="secondary"
                                input-type="time"
                                value={timesheetStartTime}
                                disabled={timesheetDateInvalid}
                                onGov-input={handleStartTimeChange}>
                            </GovFormInput>
                        </GovFormControl>
                        <GovFormControl>
                            <GovFormLabel slot="top"
                                          size="s">{timesheetType === "-1" ? "Konec dovolené" : "Konec aktivity"}</GovFormLabel>
                            <GovFormInput
                                size="s" variant="secondary"
                                input-type="time"
                                disabled={timesheetDateInvalid}
                                value={timesheetEndTime}
                                onGov-input={handleEndTimeChange}>
                            </GovFormInput>
                        </GovFormControl>
                    </div>
                }
            </div>
            <div>
                {activityCollision &&
                    <GovMessage variant="error">
                        <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                        <p>V tomto čase již existuje jiná aktivita.</p>
                    </GovMessage>
                }
            </div>
            <div>
                {(timesheetType !== "-1") &&
                    <div>
                        <GovFormControl>
                            <GovFormInput size="s" variant="secondary"
                                          disabled="disabled"
                                          value={timesheetHoursWorked}
                                          min="0"
                                          placeholder="Počet hodin"></GovFormInput>
                            <GovFormLabel slot="top" size="s">Počet hodin</GovFormLabel>
                        </GovFormControl>
                    </div>
                }
                <GovFormControl>
                    <GovFormGroup>
                        <GovFormInput size="m" variant="secondary" input-type="text" multiline="true"
                                      placeholder={timesheetType === "-1" ? "Popis dovolené" : "Popis vykonané práce"}
                                      rows="6"
                                      value={timesheetDescription}
                                      onGov-input={handleDescriptionChange}></GovFormInput>
                    </GovFormGroup>
                    <GovFormLabel slot="top"
                                  size="m">{timesheetType === "-1" ? "Popis dovolené" : "Popis vykonané práce"}</GovFormLabel>
                </GovFormControl>

            </div>
            <div>
                {userDetailsInvalid &&
                    <GovMessage variant="error">
                        <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                        <p>Uživatel nemá doplněné povinné údaje pro vyplnění timesheetu. Je nutné vyplnit všechny osobní
                            údaje.
                            <GovButton className="ml-5" type="link" variant="error"
                                       size="m"
                                       onGov-click={() => goToUserDetailPage()}>
                                Doplnit osobní údaje</GovButton></p>

                    </GovMessage>

                }
            </div>
            <div>
                {timesheetTimesRangeInvalid && timesheetType !== "-1" &&
                    <GovMessage variant="error">
                        <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                        <p>Začátek pracovní doby nesmí být stejný jako konec, případně pak začátek
                            pracovní doby musí
                            předcházet konci pracovní doby.
                            Rozdíl počtu minut mezi začátkem a koncem aktivity musí být násobkem 30. </p>
                    </GovMessage>
                }
            </div>
            <div>
                {!vacationValid && timesheetType === "-1" &&
                    <GovMessage variant="error">
                        <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                        <p>Nejsou vyplněny všechny povinné položky. Nebo konec dovolené předchází konci dovolené,
                            případně se tyto datumy shodují. Rozdíl počtu minut mezi začátkem a koncem dovolené musí být
                            násobkem 30.</p>
                    </GovMessage>
                }
            </div>
            <div>
                {!timesheetValid && timesheetType !== "-1" &&
                    <GovMessage variant="error">
                        <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                        <p>Nejsou vyplněny všechny povinné položky</p>
                    </GovMessage>
                }
            </div>
            <GovButton variant="primary" size="m" type="solid"
                       wcag-label="Open dialog box for more information"
                       disabled={(!timesheetValid && timesheetType !== "-1") || !vacationValid}
                       onGov-click={moreDaysVacation ? postMoreDaysVacation : props.create ? postWorkReport : putWorkReport}>
                <div>
                    {props.create ? "Uložit" : "Upravit"}
                </div>
            </GovButton>
        </GovModal>
    )
}
