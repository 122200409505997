import {Header} from "../Header";
import "../css/layouts.css";
import {Footer} from "../Footer";
import Navigation from "../Navigation";
import {
    GovAccordionItem,
    GovBreadcrumbs,
    GovButton, GovContainer, GovFormCheckbox, GovFormControl, GovFormFile, GovFormGroup,
    GovFormInput, GovFormLabel, GovFormMultiSelect, GovFormRadio, GovFormSelect,
    GovIcon, GovMessage, GovModal, GovSkeleton
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import {json, useNavigate, useParams} from "react-router-dom";
import {externalRoleToText, parseJwt, typeToText, userRoleToText} from "../utils/utils";
import Select, {components} from "react-select";

export function EditProjectContent() {
    const {projectId } = useParams();
    const [projectName, setProjectName] = useState(null);
    const [projectDescription, setProjectDescription] = useState(null);
    const [projectType, setProjectType] = useState(0);
    const [financingType, setFinancingType] = useState(0);
    const [projectPurpose, setProjectPurpose] = useState(0);
    const [projectApplicant, setProjectApplicant] = useState(0);
    const [projectNotes, setProjectNotes] = useState(null);
    const [projectActive, setProjectActive] = useState(null);
    const [projectRealizationProbability, setProjectRealizationProbability] = useState(null);
    const [projectIncludedAbsorptionCapacity, setProjectIncludedAbsorptionCapacity] = useState(null);
    const [request413Submitted, setRequest413Submitted] = useState(false);
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [projectConfidential, setProjectConfidential] = useState(false);
    const [applicants, setApplicants] = useState([]);
    const [projectBuildingPermit, setProjectBuildingPermit] = useState(null);
    const [projectExpenseRent, setProjectExpenseRent] = useState(null);
    const [projectCreatedHousingUnites, setProjectCreatedHousingUnites] = useState(null);
    const [projectCreatedOtherUnites, setProjectCreatedOtherUnites] = useState(null);
    const [projectFloorArea, setProjectFloorArea] = useState(null);
    const [projectNetFloorArea, setProjectNetFloorArea] = useState(null);
    const [stageOfProject, setStageOfProject] = useState(0);
    const [projectInvestmentAmount, setProjectInvestmentAmount] = useState(null);
    const [projectNetFlatsArea, setProjectNetFlatsArea] = useState(null);
    const [documentText, setDocumentText] = useState('');
    const [externalPersons, setExternalPersons] = useState([]);
    const [chosenExternalPerson, setChosenExternalPerson] = useState(null);


    const [project413Date, setProject413Date] = useState(null);
    const [project413Volume, setProject413Volume] = useState(null);
    const [project413SerialNumber, setProject413SerialNumber] = useState(null);

    const [project210Date, setProject210Date] = useState(null);
    const [project210Volume, setProject210Volume] = useState(null);
    const [project210SerialNumber, setProject210SerialNumber] = useState(null);

    const [projectValid, setProjectValid] = useState(true)

    const [projectNameInvalid, setProjectNameInvalid] = useState(false)
    const [projectDescriptionInvalid, setProjectDescriptionInvalid] = useState(false)
    const [projectApplicantInvalid, setProjectApplicantInvalid] = useState(false)
    const [projectTypeInvalid, setProjectTypeInvalid] = useState(false)
    const [housingUnitsInvalid, setHousingUnitsInvalid] = useState(false)
    const [otherHousingUnitsInvalid, setOtherHousingUnitsInvalid] = useState(false)
    const [builtAreaInvalid, setBuiltAreaInvalid] = useState(false)
    const [netBuiltAreaInvalid, setNetBuiltAreaInvalid] = useState(false)
    const [investmentAmountInvalid, setInvestmentAmountInvalid] = useState(false)
    const [realizationProbabilityInvalid, setRealizationProbabilityInvalid] = useState(false)
    const [assignedRcEmployeeInvalid, setAssignedRcEmployeeInvalid] = useState(false)
    const [stageInvalid, setStageInvalid] = useState(false)

    function checkErrors() {
        let ready = !projectNameInvalid && !projectDescriptionInvalid && !assignedRcEmployeeInvalid
        && !realizationProbabilityInvalid
        setProjectValid(ready)
    }

    useEffect(() => {
        checkErrors()
    }, [projectNameInvalid, projectDescriptionInvalid, assignedRcEmployeeInvalid, realizationProbabilityInvalid]);


    const [contactPerson, setContactPerson] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [town, setTown] = useState({});
    const [rcPeople, setRcPeople] = useState();
    const [assignedRcPerson, setAssignedRcPerson] = useState('');
    const handleTownPersonChange = (event) => {
        setPersonRole(event.target.value);
    };
    const jwt = localStorage.getItem("jwt");

    function fetchProjectApplicants() {
        fetch(process.env.REACT_APP_API_URL+"/projectSubjects", {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                const applicantsArray = jsonData.map(applicant => {
                    return {value: applicant.id, label: applicant.name + " - " + typeToText(applicant.type)}
                })
                console.log(applicantsArray)
                setApplicants(applicantsArray)
                // setApplicants((currentApplicants) => {
                //     const newApplicants = jsonData.filter(
                //         (newApplicant) => !currentApplicants.some(
                //             (currentApplicant) => currentApplicant.id === newApplicant.id
                //         )
                //     );
                //     return [...currentApplicants, ...newApplicants];
                // })
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };


    const fetchPeopleData = (regionalCenterId) => {
        fetch(process.env.REACT_APP_API_URL+"/rc/"+ regionalCenterId +"/employees", {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setRcPeople(jsonData)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const navigate = useNavigate();

    function putNewProject() {
        if (projectValid) {
            return fetch(process.env.REACT_APP_API_URL + `/projects/${projectId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                },
                body: JSON.stringify({
                    "name": projectName,
                    "description": projectDescription,
                    "type":  projectType,
                    "subjectId":  projectApplicant,
                    "netFlatsArea": projectNetFlatsArea,
                    "buildingPermitDate": projectBuildingPermit,
                    "housingUnitsCount": projectCreatedHousingUnites,
                    "builtArea": projectFloorArea,
                    "netBuiltArea": projectNetFloorArea,
                    "financing":  financingType,
                    "realizationProbability": projectRealizationProbability / 100,
                    "investmentAmount": projectInvestmentAmount,
                    "otherHousingUnitsCount": projectCreatedOtherUnites,
                    "finishedPhase": stageOfProject,
                    "assignedRcEmployee": assignedRcPerson,
                    "notes": projectNotes,
                    "active": projectActive,
                    "request413": request413Submitted,
                    "acquired413Date": project413Date,
                    "acquired413Amount": project413Volume,
                    "acquired413RefNum": project413SerialNumber,
                    "requestSubmitted": requestSubmitted,
                    "acquired210Date": project210Date,
                    "acquired210Amount": project210Volume,
                    "acquired210RefNum": project210SerialNumber,
                    "includedAbsorptionCapacity": projectIncludedAbsorptionCapacity,
                    "confidential": projectConfidential,
                    "purpose":  projectPurpose,
                    "expenseRent": projectExpenseRent,
                    "townId": town.id,
                    "contactPersonId": chosenExternalPerson
                })
            })
                .then(response => response.json())
                .then(data => {
                    navigate(`/projects/detail/${projectId}`)
                });
        }
    }

    const [personName, setPersonName] = useState('');
    const [personSurname, setPersonSurname] = useState('');
    const [personTelephone, setPersonTelephone] = useState('');
    const [personEmail, setPersonEmail] = useState('');
    const [personRole, setPersonRole] = useState('');
    const [personOrganization, setPersonOrganization] = useState('');
    const [subjectType, setSubjectType] = useState('');
    const [subjectName, setSubjectName] = useState('');


    function openModal(){
        setModalOpen(true)
    }
    function closeModal(){
        setModalOpen(false)
    }

    function postNewProjectApplicant() {
        return fetch(process.env.REACT_APP_API_URL+ "/projectSubjects", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            body: JSON.stringify({
                "name": subjectName,
                "type": subjectType,
            })
        })
            .then(response => {
                if (response.ok) {
                    return 'Request successful';
                } else {
                    throw new Error('Request failed');
                }
            })
            .catch(error => {
                return error.message;
            });
    }

    function addApplicant(){

        postNewProjectApplicant().then(() => {
            fetchProjectApplicants();
        });
        setSubjectName("")
        setSubjectType("")
        closeModal()
    }

    const handleAssignedRcPersonChange = (event) => {
        if (event.target.value === "") {
            setAssignedRcEmployeeInvalid(true)
        } else {
           setAssignedRcEmployeeInvalid(false)
        }
        setAssignedRcPerson(event.target.value);
    };

    const handleProjectExpenseRentChange = (event) => {
        setProjectExpenseRent(event.target.value);
    };

    const handleProjectCreatedHousingUnitesChange = (event) => {
        if (event.target.value === "" || event.target.value < 0) {
            setHousingUnitsInvalid(true)
        } else {
            setHousingUnitsInvalid(false)
        }
        setProjectCreatedHousingUnites(event.target.value);
    };
    const handleProjectCreatedOtherUnitesChange = (event) => {
        if (event.target.value === "" || event.target.value < 0) {
            setOtherHousingUnitsInvalid(true)
        } else {
            setOtherHousingUnitsInvalid(false)
        }
        setProjectCreatedOtherUnites(event.target.value);
    };
    const handleProjectFloorAreaChange = (event) => {
        if (event.target.value === "" || event.target.value < 0) {
            setBuiltAreaInvalid(true)
        } else {
            setBuiltAreaInvalid(false)
        }
        setProjectFloorArea(event.target.value);
    };
    const handleProjectNetFloorAreaChange = (event) => {
        if (event.target.value === "" || event.target.value < 0) {
            setNetBuiltAreaInvalid(true)
        } else {
            setNetBuiltAreaInvalid(false)
        }
        setProjectNetFloorArea(event.target.value);
    };
    const handleExternalPersonChange = (event) => {
        setChosenExternalPerson(event.value);
    };
    const handleProjectInvestmentAmountChange = (event) => {
        if (event.target.value === ""|| event.target.value < 0) {
            setInvestmentAmountInvalid(true)
        } else {
            setInvestmentAmountInvalid(false)
        }
        setProjectInvestmentAmount(event.target.value);
    };
    const handleRealizationProbabilityChange = (event) => {
        if (event.target.value === ""|| event.target.value < 0 || event.target.value > 100) {
            setRealizationProbabilityInvalid(true)
        } else {
            setRealizationProbabilityInvalid(false)
        }
        setProjectRealizationProbability(event.target.value);
    };

    const handleSubjectNameChange = (event) => {
        setSubjectName(event.target.value);
    };
    const handleSubjectTypeChange = (event) => {
        setSubjectType(event.target.value);
    }


    const handleProjectNameChange = (event) => {
        if (event.target.value === "") {
            setProjectNameInvalid(true)
        } else {
            setProjectNameInvalid(false)
        }
        setProjectName(event.target.value);
    };
    const handleProjectDescriptionChange = (event) => {
        if (event.target.value === "" || event.target.value === null) {
            setProjectDescriptionInvalid(true)
        } else {
            setProjectDescriptionInvalid(false)
        }
        setProjectDescription(event.target.value);
    };
    const handleProjectApplicantChange = (event) => {
        if (event.value === "") {
            setProjectApplicantInvalid(true)
        } else {
            setProjectApplicantInvalid(false)
        }
        setProjectApplicant(event.value);
    };
    const handleTypeOfProjectChange = (event) => {
        if (event.target.value === "") {
            setProjectTypeInvalid(true)
        } else {
            setProjectTypeInvalid(false)
        }
        setProjectType(event.target.value);
    };
    const handleFinancingTypeChange = (event) => {
        setFinancingType(event.target.value);
    };

    const handleProjectNotesChange = (event) => {
        setProjectNotes(event.target.value);
    };

    const handleProjectActiveChange = (event) => {
        setProjectActive(!projectActive);
    };

    const handleProjectIncludedAbsorptionCapacityChange = (event) => {
        setProjectIncludedAbsorptionCapacity(!projectIncludedAbsorptionCapacity);
    };

    const handleRequest413SubmittedChange = (event) => {
        if(!request413Submitted) {
            setProject413Date(null)
            setProject413Volume(null)
            setProject413SerialNumber(null)
        }
        setRequest413Submitted(!request413Submitted);
    };

    const handleRequestSubmittedChange = (event) => {
        if(!requestSubmitted) {
            setProject210Date(null)
            setProject210Volume(null)
            setProject210SerialNumber(null)
        }
        setRequestSubmitted(!requestSubmitted);
    };

    const handleProjectConfidentialChange = (event) => {
        setProjectConfidential(!projectConfidential);
    };

    const handleProjectPurposeChange = (event) => {
        setProjectPurpose(event.target.value);
    };
    const handleProjectBuildingPermitChange = (event) => {
        setProjectBuildingPermit(event.target.value);
    };

    const handleProjectNetFlatsAreaChange = (event) => {
        setProjectNetFlatsArea(event.target.value);
    };

    const handleProject413DateChange = (event) => {
        setProject413Date(event.target.value);
    };

    const handleProject413VolumeChange = (event) => {
        setProject413Volume(event.target.value);
    }

    const handleProject413serialNumberChange = (event) => {
        setProject413SerialNumber(event.target.value);
    }

    const handleProject210DateChange = (event) => {
        setProject210Date(event.target.value);
    };

    const handleProject210VolumeChange = (event) => {
        setProject210Volume(event.target.value);
    }

    const handleProject210serialNumberChange = (event) => {
        setProject210SerialNumber(event.target.value);
    }

    const handleRadioChange = (event) => {
        if (event.target.value === "") {
            setStageInvalid(true)
        } else {
            setStageInvalid(false)
        }
        setStageOfProject(event.target.value);
        if(event.target.value==="Vize"){
            setDocumentText(`<div>Pokud máte hotovou fázi <b>"Vize"</b>, nahrajte
            prosím následující dokumenty (můžete je nahrát i později):
            <ul class="mt-20">
                <li>Záměr projektu</li>
            </ul>
            </div>`)
        } else if(event.target.value==="Předpojektová příprava"){
            setDocumentText(`<div>Pokud máte hotovou fázi <b>"Předprojektová příprava"</b>, nahrajte
            prosím následující dokumenty (můžete je nahrát i později):
            <ul class="mt-20">
                <li >Záměr projektu</li>
                <li>Studie proveditelnosti</li>
                <li>Usnesení zastupitelstva o zahájení projektových prací</li>
                <li>Usnesení zastupitelstva o schválení studie proveditelnosti a pověření starosty zadáním projektových prací</li>
            </ul>
            </div>`)
        } else if(event.target.value==="Projektová práce"){
            setDocumentText(`<div>Pokud máte hotovou fázi <b>"Projektová práce"</b>, nahrajte
            prosím následující dokumenty (můžete je nahrát i později):
            <ul class="mt-20">
                <li>Záměr projektu</li>
                <li>Studie proveditelnosti</li>
                <li>Usnesení zastupitelstva o zahájení projektových prací</li>
                <li>Usnesení zastupitelstva o schválení studie proveditelnosti a pověření starosty zadáním projektových prací</li>
                <li>Dokumentace pro územní rozhodnutí vč. dokladové části</li>
                <li>Územní rozhodnutí s nabytím právní moci</li>
                <li>Dokumentace pro stavební povolení včetně dokladové části</li>
                <li>Usnesení zastupitelstva o pokračocání realizace projektu</li>
            </ul>
            </div>`)
        } else if(event.target.value==="Stavební povolení - příprava na realizaci"){
            setDocumentText(`<div>Pokud máte hotovou fázi <b>"Stavební povolení - příprava na realizaci"</b>, nahrajte
            prosím následující dokumenty (můžete je nahrát i později):
            <ul class="mt-20">
                <li>Záměr projektu</li>
                <li>Studie proveditelnosti</li>
                <li>Usnesení zastupitelstva o zahájení projektových prací</li>
                <li>Usnesení zastupitelstva o schválení studie proveditelnosti a pověření starosty zadáním projektových prací</li>
                <li>Dokumentace pro územní rozhodnutí vč. dokladové části</li>
                <li>Územní rozhodnutí s nabytím právní moci</li>
                <li>Dokumentace pro stavební povolení včetně dokladové části</li>
                <li>Usnesení zastupitelstva o pokračocání realizace projektu</li>
                <li>Stavební povolení s nabytím právní moci</li>
                <li>Dokumentace pro výběr zhotovitele</li>
            </ul>
            </div>`)
        } else if(event.target.value==="Realizace a provoz budovy"){
            setDocumentText(`<div>Pokud máte hotovou fázi <b>"Realizace a provoz budovy"</b>, nahrajte
            prosím následující dokumenty (můžete je nahrát i později):
            <ul class="mt-20">
                <li>Záměr projektu</li>
                <li>Studie proveditelnosti</li>
                <li>Usnesení zastupitelstva o zahájení projektových prací</li>
                <li>Usnesení zastupitelstva o schválení studie proveditelnosti a pověření starosty zadáním projektových prací</li>
                <li>Dokumentace pro územní rozhodnutí vč. dokladové části</li>
                <li>Územní rozhodnutí s nabytím právní moci</li>
                <li>Dokumentace pro stavební povolení včetně dokladové části</li>
                <li>Usnesení zastupitelstva o pokračocání realizace projektu</li>
                <li>Stavební povolení s nabytím právní moci</li>
                <li>Dokumentace pro výběr zhotovitele</li>
                <li>Kolaudační rozhodnutí</li>
                <li>Smluvní zajištění objektu</li>
            </ul>
            </div>`)
        }
    };

    const fetchProjectData = () => {
        return fetch(process.env.REACT_APP_API_URL+"/projects/"+ projectId, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setProjectName(jsonData.name)
                setProjectDescription(jsonData.description)
                setAssignedRcPerson(jsonData.assignedRcEmployeeId)
                setProjectType(jsonData.type)
                if(jsonData.projectSubject){
                    setProjectApplicant(jsonData.projectSubject.id)
                }

                setProjectCreatedHousingUnites(jsonData.housingUnitsCount)
                setProjectCreatedOtherUnites(jsonData.otherHousingUnitsCount)
                setChosenExternalPerson(jsonData.contactPersonId)
                setFinancingType(jsonData.financing)
                setProjectFloorArea(jsonData.builtArea)
                setProjectNetFloorArea(jsonData.netBuiltArea)
                setProjectInvestmentAmount(jsonData.investmentAmount)
                setStageOfProject(jsonData.finishedPhase.toString())

                setProjectExpenseRent(jsonData.expenseRent)
                setProjectNotes(jsonData.notes)
                setProjectActive(jsonData.active)
                setProjectRealizationProbability(jsonData.realizationProbability*100)
                setRequest413Submitted(jsonData.request413)
                setProject413Date(jsonData.acquired413Date)
                setProject413Volume(jsonData.acquired413Amount)
                setProject210SerialNumber(jsonData.acquired210RefNum)
                setProject210Date(jsonData.acquired210Date)
                setProject210Volume(jsonData.acquired210Amount)
                setProject413SerialNumber(jsonData.acquired413RefNum)
                setRequestSubmitted(jsonData.requestSubmitted)
                setProjectIncludedAbsorptionCapacity(jsonData.includedAbsorptionCapacity)
                setProjectConfidential(jsonData.confidential)

                if(jsonData.purpose){
                    setProjectPurpose(jsonData.purpose)
                }

                setProjectBuildingPermit(jsonData.buildingPermitDate)
                setProjectNetFlatsArea(jsonData.netFlatsArea)

                if (jsonData.name === "" || jsonData.name === null) {
                    setProjectNameInvalid(true)
                } else {
                    setProjectNameInvalid(false)
                }
                if (jsonData.description === "" || jsonData.description === null) {
                    setProjectDescriptionInvalid(true)
                } else {
                    setProjectDescriptionInvalid(false)
                }
                if (jsonData.projectSubject === "" || jsonData.projectSubject === null) {
                    setProjectApplicantInvalid(true)
                } else {
                    setProjectApplicantInvalid(false)
                }

                setTown(jsonData.town)
                console.log(jsonData.town)
                const externalPersonsArray = jsonData.town.externalPersons.map(externalPerson => {
                    return {value: externalPerson.id, label: externalPerson.firstname + " " + externalPerson.lastname + " - " + externalRoleToText(externalPerson.role)
                            + " (" + externalPerson.email+")"}
                })
                setExternalPersons(externalPersonsArray)
                fetchPeopleData(jsonData.town.regionalCenter.id)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        fetchProjectData()
        fetchProjectApplicants()
        checkErrors()
    }, []);

    const DropdownIndicator = (
        props
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <GovIcon type="basic" name="chevron-down"></GovIcon>
            </components.DropdownIndicator>
        );
    };
    const customStyles = {
        dropdownIndicator: (styles, state) => ({
            ...styles,
            width: "28px",
            marginRight: "5px",
            "&:hover": {
                color: "#686868"
            },
            color: state.isFocused ? "#686868" : "#686868",
            fill: "red"
        }),
        option: (styles, state) => ({
            ...styles,
            cursor: 'pointer',
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            cursor: 'pointer',
            borderRadius: '3px',
            "&:hover": {
                borderColor: "#2362a2"
            },
            borderColor: '#686868',
            minHeight: '32px',
            height: '32px',
            boxShadow: state.isFocused ? null : null,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            color: '#686868',
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            fontSize: '14px',
            padding: '0 8px',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            color: '#686868',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#686868',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '32px',
        }),
    };

    const user = parseJwt(localStorage.getItem("jwt"))
    const authority = user && user.role[0].authority


    return (
        <div>
            <Navigation active={"projects"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/towns/search">Obce</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            {(town && town.id)  ?
                                <a href={`/towns/${town.id.toString().length > 6 ? "collective/" : ""}detail/${town.id}`}>
                                    {town.name} {town.id.toString().length > 6 && "(DSO)"}</a> :
                                <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                             animation="progress" count="1" width="300px"
                                             height="30px"></GovSkeleton>
                            }
                        </li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Editace projektu</strong></li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">

                        <div className="gov-title--beta">Editace projektu</div>

                        <div className="form-help-layout gov-tabs">
                            <div className="gov-tabs__content-holder">
                                <div className="gov-tabs__content is-active" role="tabpanel">
                                    <form method="post">
                                        <GovFormLabel className="gov-registration-label mb-20" slot="top"
                                                      size="xl">Obecné</GovFormLabel>

                                        <h4>Název obce</h4>
                                        <p>{town.name}</p>
                                        <h4>Kód obce</h4>
                                        <p>{town.id}</p>
                                        <GovFormControl>
                                            <GovFormInput value={projectName} onGov-input={handleProjectNameChange}
                                                          size="s" variant="secondary"
                                                          input-type="text" placeholder="Název projektu"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Název projektu*</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          input-type="text" multiline="true"
                                                          placeholder="Uveďte prosím popis projektu"
                                                          value={projectDescription} onGov-input={handleProjectDescriptionChange}>
                                            </GovFormInput>
                                            <GovFormLabel slot="top" size="s">Popis projektu*</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormCheckbox
                                                size="m"
                                                onGov-change={handleProjectActiveChange}
                                                checked={projectActive}
                                            >
                                                <GovFormLabel size="m"
                                                              slot="label">Živý projekt</GovFormLabel>
                                            </GovFormCheckbox>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormCheckbox
                                                size="m"
                                                onGov-change={handleProjectIncludedAbsorptionCapacityChange}
                                                checked={projectIncludedAbsorptionCapacity}
                                            >
                                                <GovFormLabel size="m"
                                                              slot="label">Zahrnuto v absorpční kapacitě</GovFormLabel>
                                            </GovFormCheckbox>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormCheckbox
                                                size="m"
                                                onGov-change={handleRequest413SubmittedChange}
                                                checked={request413Submitted}
                                            >
                                                <GovFormLabel size="m"
                                                              slot="label">Podaná žádost - Subkomponenta 4.1.3</GovFormLabel>
                                            </GovFormCheckbox>
                                        </GovFormControl>
                                        {request413Submitted &&
                                        <div>
                                            <GovFormControl>
                                                <GovFormLabel slot="top" size="s">Přiděleno ze 4.1.3 – Datum</GovFormLabel>
                                                <GovFormInput
                                                    size="s" variant="secondary"
                                                    input-type="date"
                                                    value={project413Date}
                                                    onGov-input={handleProject413DateChange}>
                                                </GovFormInput>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormInput size="s" variant="secondary"
                                                              onGov-input={handleProject413VolumeChange}
                                                              input-type="number" value={project413Volume}
                                                              placeholder="Přiděleno ze 4.1.3 – Objem (v mil.
                                                    Kč)"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">Přiděleno ze 4.1.3 – Objem (v mil. Kč)</GovFormLabel>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormInput value={project413SerialNumber} onGov-input={handleProject413serialNumberChange}
                                                              size="s" variant="secondary"
                                                              input-type="text" placeholder="Přiděleno ze 4.1.3 – Číslo jednací"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">Přiděleno ze 4.1.3 – Číslo jednací</GovFormLabel>
                                            </GovFormControl>
                                        </div>
                                        }
                                        <GovFormControl>
                                            <GovFormCheckbox
                                                size="m"
                                                onGov-change={handleRequestSubmittedChange}
                                                checked={requestSubmitted}
                                            >
                                                <GovFormLabel size="m"
                                                              slot="label">Podaná žádost - Komponenta 2.10</GovFormLabel>
                                            </GovFormCheckbox>
                                        </GovFormControl>
                                        {requestSubmitted &&
                                            <div>
                                                <GovFormControl>
                                                    <GovFormLabel slot="top" size="s">Přiděleno z 2.10 – Datum</GovFormLabel>
                                                    <GovFormInput
                                                        size="s" variant="secondary"
                                                        input-type="date"
                                                        value={project210Date}
                                                        onGov-input={handleProject210DateChange}>
                                                    </GovFormInput>
                                                </GovFormControl>
                                                <GovFormControl>
                                                    <GovFormInput size="s" variant="secondary"
                                                                  onGov-input={handleProject210VolumeChange}
                                                                  input-type="number" value={project210Volume}
                                                                  placeholder="Přiděleno z 2.10 – Objem (v mil.
                                                    Kč)"></GovFormInput>
                                                    <GovFormLabel slot="top" size="s">Přiděleno z 2.10 – Objem (v mil. Kč)</GovFormLabel>
                                                </GovFormControl>
                                                <GovFormControl>
                                                    <GovFormInput value={project210SerialNumber} onGov-input={handleProject210serialNumberChange}
                                                                  size="s" variant="secondary"
                                                                  input-type="text" placeholder="Přiděleno z 2.10 – Číslo jednací"></GovFormInput>
                                                    <GovFormLabel slot="top" size="s">Přiděleno z 2.10 – Číslo jednací</GovFormLabel>
                                                </GovFormControl>
                                            </div>
                                        }
                                        <GovFormControl>
                                            <GovFormCheckbox
                                                size="m"
                                                onGov-change={handleProjectConfidentialChange}
                                                checked={projectConfidential}
                                            >
                                                <GovFormLabel size="m"
                                                              slot="label">Důvěrné informace</GovFormLabel>
                                            </GovFormCheckbox>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          input-type="text" multiline="true"
                                                          placeholder="Uveďte prosím poznámky k projektu"
                                                          value={projectNotes} onGov-input={handleProjectNotesChange}>
                                            </GovFormInput>
                                            <GovFormLabel slot="top" size="s">Poznámky k projektu*</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>

                                            <div>
                                                {applicants && applicants.length !== 0 &&
                                                    <Select options={applicants} onChange={handleProjectApplicantChange}
                                                            placeholder={"Subjekt (žadatel)"}
                                                            value={applicants.find(applicant => applicant.value === projectApplicant)}
                                                            components={{DropdownIndicator}}
                                                            styles={customStyles}
                                                            noOptionsMessage={() => "Nenašli jsme žádné subjekty"}

                                                    />
                                                }
                                            </div>

                                            {/*<GovFormSelect size="s" placeholder="Subjekt (žadatel)"*/}
                                            {/*               onGov-change={handleProjectApplicantChange}*/}
                                            {/*               value={projectApplicant}>*/}
                                            {/*    <option disabled="disabled" value="0">Uveďte prosím subjekt</option>*/}
                                            {/*    {applicants.length > 0 &&*/}
                                            {/*        applicants.filter(applicant => applicant.id !== 0).map((applicant, index) => (*/}
                                            {/*            <option*/}
                                            {/*                key={index}*/}
                                            {/*                value={applicant.id}>{applicant.name + " - " + typeToText(applicant.type)}*/}
                                            {/*            </option>*/}
                                            {/*        ))*/}
                                            {/*    }*/}
                                            {/*</GovFormSelect>*/}

                                            <GovFormLabel slot="top" size="s">Subjekt (žadatel)*</GovFormLabel>
                                            <GovButton variant="primary" size="s" type="link"
                                                       wcag-label="Open dialog box for more information"
                                                       onGov-click={openModal}>+ Přidat další subjekt
                                            </GovButton>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <div>
                                                {externalPersons && externalPersons.length !== 0 ?
                                                    <Select options={externalPersons} onChange={handleExternalPersonChange}
                                                            placeholder={"Kontaktní osoba"}
                                                            value={externalPersons.find(externalPerson => externalPerson.value === chosenExternalPerson)}
                                                            components={{DropdownIndicator}}
                                                            styles={customStyles}
                                                            noOptionsMessage={() => "Nenašli jsme žádné osoby"}

                                                    /> :
                                                    <span>V obci nejsou evidovány, žádné osoby. Pro založení externí osoby je nutné založit konzultaci s dalšími účastníky.</span>
                                                }
                                            </div>
                                            <GovFormLabel slot="top" size="s">Kontaktní osoba</GovFormLabel>
                                        </GovFormControl>

                                        <GovModal open={modalOpen} onGov-close={closeModal}
                                                  label="Přidání dalšího subjektu"
                                                  wcag-close-label="Close dialog box with more information">
                                            <GovFormControl>
                                                <GovFormInput value={subjectName}
                                                              onGov-input={handleSubjectNameChange}
                                                              size="s" variant="secondary"
                                                              input-type="text" placeholder="Uveďte prosím název subjektu"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">Název subjektu*</GovFormLabel>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <div className="sfpi-title-section mt-20">Typ subjektu
                                                </div>
                                                <GovFormGroup gap="s">
                                                    <GovFormRadio checked={subjectType === "TOWN"}
                                                                  onGov-change={handleSubjectTypeChange}
                                                                  value="TOWN" size="s"
                                                                  name="subject-radio">
                                                        <GovFormLabel size="s" slot="label">Obec/Město/Městská část
                                                        </GovFormLabel>
                                                    </GovFormRadio>
                                                    <GovFormRadio checked={subjectType === "FUNDED_ORGANIZATION"}
                                                                  onGov-change={handleSubjectTypeChange}
                                                                  value="FUNDED_ORGANIZATION"
                                                                  size="s"
                                                                  name="subject-radio">
                                                        <GovFormLabel size="s" slot="label">Příspěvková
                                                            organizace</GovFormLabel>
                                                    </GovFormRadio>
                                                    <GovFormRadio checked={subjectType === "COUNTRY"}
                                                                  onGov-change={handleSubjectTypeChange}
                                                                  value="COUNTRY" size="s" name="subject-radio">
                                                        <GovFormLabel size="s" slot="label">Stát
                                                        </GovFormLabel>
                                                    </GovFormRadio>
                                                    <GovFormRadio checked={subjectType === "CHURCH"}
                                                                  onGov-change={handleSubjectTypeChange}
                                                                  value="CHURCH"
                                                                  size="s" name="subject-radio">
                                                        <GovFormLabel size="s" slot="label">Církev
                                                        </GovFormLabel>
                                                    </GovFormRadio>
                                                    <GovFormRadio checked={subjectType === "ASSOCIATION"}
                                                                  onGov-change={handleSubjectTypeChange}
                                                                  value="ASSOCIATION"
                                                                  size="s" name="subject-radio">
                                                        <GovFormLabel size="s" slot="label">Spolek
                                                        </GovFormLabel>
                                                    </GovFormRadio>
                                                    <GovFormRadio checked={subjectType === "PRIVATE"}
                                                                  onGov-change={handleSubjectTypeChange}
                                                                  value="PRIVATE"
                                                                  size="s" name="subject-radio">
                                                        <GovFormLabel size="s" slot="label">Privátní subjekt
                                                        </GovFormLabel>
                                                    </GovFormRadio>
                                                </GovFormGroup>
                                            </GovFormControl>
                                            <GovButton variant="primary" size="m" type="solid"
                                                       wcag-label="Open dialog box for more information"
                                                       onGov-click={addApplicant}>Přidat
                                            </GovButton>
                                        </GovModal>
                                        <GovFormControl>
                                            <GovFormSelect value={projectType} onGov-change={handleTypeOfProjectChange}
                                                           size="s" variant="secondary">
                                                <option disabled="disabled" value="0">Typ projektu (převažující typ v případě mixu)</option>
                                                <option value="1">Nákup</option>
                                                <option value="2">Stavba</option>
                                                <option value="3">Rekonstrukce</option>
                                                {/*<option value="4">Mix</option>*/}
                                            </GovFormSelect>
                                            <GovFormLabel slot="top" size="s">Typ projektu (převažující typ v případě mixu)*</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormSelect value={financingType} onGov-change={handleFinancingTypeChange}
                                                           size="s" variant="secondary">
                                                <option disabled="disabled" value="0">Typ financování (převažující)
                                                </option>
                                                <option value="1">4.1.3 – Podpora
                                                    přípravy projektů
                                                </option>
                                                <option value="2">2.10 – Dostupné bydlení</option>
                                                <option value="3">IROP – Sociální bydlení</option>
                                                <option
                                                    value="4">PPP
                                                    – Spolu-investice žadatele s bankou, developerem, investorem, apod.
                                                </option>
                                                <option value="5">Komerční úvěr
                                                </option>
                                                <option value="6">Jiné
                                                </option>
                                            </GovFormSelect>
                                            <GovFormLabel slot="top" size="s">Typ financování (převažující)*</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormSelect value={projectPurpose}
                                                           onGov-change={handleProjectPurposeChange}
                                                           size="s" variant="secondary">
                                                <option disabled="disabled" value="0">Účel bytové výstavby u projektu
                                                </option>
                                                <option value="1">Prodej bytů
                                                </option>
                                                <option value="2">Pronájem tržní</option>
                                                <option value="3">Pronájem zvýhodněný</option>
                                                <option
                                                    value="4">Mix
                                                </option>
                                            </GovFormSelect>
                                            <GovFormLabel slot="top" size="s">Účel bytové výstavby u projektu*</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormLabel slot="top" size="s">Datum vydání stavebního povolení*</GovFormLabel>
                                            <GovFormInput
                                                size="s" variant="secondary"
                                                input-type="date"
                                                value={projectBuildingPermit}
                                                onGov-input={handleProjectBuildingPermitChange}>
                                            </GovFormInput>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          onGov-input={handleProjectCreatedHousingUnitesChange}
                                                          input-type="number" value={projectCreatedHousingUnites}
                                                          placeholder="Počet bytových jednotek celkem, které díky projektu vzniknou"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Počet bytových jednotek celkem, které díky
                                                projektu vzniknou</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          input-type="number"
                                                          placeholder="Z toho počet jiných bytových jednotek (sociální/bezbariérových), které díky
                                                projektu vzniknou z celkového počtu"
                                                          onGov-input={handleProjectCreatedOtherUnitesChange} value={projectCreatedOtherUnites}></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Z toho počet jiných bytových jednotek (sociální/bezbariérových), které díky
                                                projektu vzniknou z celkového počtu</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          onGov-input={handleProjectFloorAreaChange}
                                                          input-type="number" value={projectFloorArea}
                                                          placeholder="Počet m2 hrubé podlažní plochy (HPP) celého objektu"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Počet m<sup>2</sup> hrubé podlažní plochy
                                                (HPP) celého objektu</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          onGov-input={handleProjectNetFloorAreaChange}
                                                          input-type="number" value={projectNetFloorArea}
                                                          placeholder="Počet m2 čisté podlažní plochy (ČPP) celého objektu"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Počet m<sup>2</sup> čisté podlažní plochy
                                                (ČPP) celého objektu</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          onGov-input={handleProjectNetFlatsAreaChange}
                                                          input-type="number" min="0" value={projectNetFlatsArea}
                                                          placeholder="Počet m2 čisté podlažní plochy (ČPP) bytů"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Počet m<sup>2</sup> čisté podlažní plochy (ČPP) bytů</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          onGov-input={handleProjectExpenseRentChange}
                                                          value={projectExpenseRent}
                                                          input-type="number" min="0"
                                                          placeholder="Nákladové nájemné (Kč/m2/měsíc)"></GovFormInput>
                                            <GovFormLabel slot="top" size="">Nákladové nájemné (Kč/m<sup>2</sup>/měsíc)</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          onGov-input={handleProjectInvestmentAmountChange}
                                                          input-type="number" value={projectInvestmentAmount}
                                                          placeholder="Očekávaná výše investice (v mil. Kč)"></GovFormInput>
                                            <GovFormLabel slot="top" size="">Očekávaná výše investice (v mil.
                                                Kč)</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormGroup gap="m">

                                                {rcPeople && rcPeople.map((person, index) => (
                                                    <GovFormRadio onGov-change={handleAssignedRcPersonChange}
                                                                  key={index}
                                                                  value={person.id} size="s" checked={assignedRcPerson === person.id}
                                                                  name="rc-employee">
                                                        <GovFormLabel size="s" slot="label">{person.firstname + " " + person.lastname + " - " + userRoleToText(person.role)}</GovFormLabel>
                                                    </GovFormRadio>
                                                ))}
                                            </GovFormGroup>
                                            <GovFormLabel slot="top" size="s">Přiřazená osoba k projektu</GovFormLabel>
                                        </GovFormControl>
                                        <div className="sfpi-title-section mt-20">Kterou fázi projektu máte hotovou
                                        </div>
                                        <GovFormControl>
                                            <GovFormGroup gap="s">
                                                <GovFormRadio onGov-change={handleRadioChange} value="1" size="s"
                                                              name="list-radio" checked={"1" === stageOfProject}>
                                                    <GovFormLabel size="s" slot="label">Vize</GovFormLabel>
                                                </GovFormRadio>
                                                <GovFormRadio onGov-change={handleRadioChange}
                                                              value="2" size="s" name="list-radio" checked={"2" === stageOfProject}>
                                                    <GovFormLabel size="s" slot="label">Předprojektová
                                                        příprava</GovFormLabel>
                                                </GovFormRadio>
                                                <GovFormRadio onGov-change={handleRadioChange} value="3"
                                                              size="s" name="list-radio" checked={"3" === stageOfProject}>
                                                    <GovFormLabel size="s" slot="label">Projektová práce</GovFormLabel>
                                                </GovFormRadio>
                                                <GovFormRadio onGov-change={handleRadioChange}
                                                              value="4" size="s"
                                                              name="list-radio" checked={"4" === stageOfProject}>
                                                    <GovFormLabel size="s" slot="label">Stavební povolení - příprava na
                                                        realizaci</GovFormLabel>
                                                </GovFormRadio>
                                                <GovFormRadio onGov-change={handleRadioChange}
                                                              value="5" checked={"5" === stageOfProject} size="s"
                                                              name="list-radio">
                                                    <GovFormLabel size="s" slot="label">Realizace a provoz
                                                        budovy</GovFormLabel>
                                                </GovFormRadio>
                                            </GovFormGroup>
                                        </GovFormControl>
                                        {authority === "HEADQUARTERS" &&
                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          onGov-input={handleRealizationProbabilityChange}
                                                          input-type="number" value={projectRealizationProbability}
                                                          min="0" max="100"
                                                          placeholder="Očekávaná pravděpodobnost realiazce projektu"></GovFormInput>
                                            <GovFormLabel slot="top" size="">Pravděpodobnost realizace projektu v %</GovFormLabel>
                                        </GovFormControl>
                                        }
                                        {realizationProbabilityInvalid &&
                                            <GovMessage variant="error"><p>Hodnota pravděpodobnosti musí být vyplněna v rozsahu od 0 % do 100 %.</p></GovMessage>}
                                        { !projectValid &&
                                            <GovMessage variant="error"><p>Nejsou vyplněny všechny povinné položky</p></GovMessage>}
                                        <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                                   type="solid" size="m" onGov-click={putNewProject} disabled={!projectValid}>Uložit
                                        </GovButton>

                                    </form>
                                </div>

                                <div className="form-help-column gov-tabs__content " role="tabpanel">

                                    <h4>Nápověda</h4>

                                    <GovAccordionItem variant="primary" size="xs">
                                        <p><b>Nákup</b> - Cílem projektu je zprostředkovat dostupné bydlení nákupem
                                            existujících nemovitostí</p>
                                        <p><b>Stavba</b> - Cílem projektu je zprostředkovat dostupné bydlení výstavbou
                                            nových nemovitostí
                                        </p>
                                        <p><b>Rekonstrukce</b> - Cílem projektu je zprostředkovat dostupné bydlení
                                            rekonstrukcí
                                            existujících nemovitostí</p>
                                        <p><b>Mix</b> - Cílem projektu je zprostředkovat dostupné bydlení libovolnou
                                            kombinací přístupů
                                            (nákupem, stavbou i rekonstrukcí)</p>

                                        <p slot="label">Typ projektu</p>
                                    </GovAccordionItem>
                                    <GovAccordionItem variant="primary" size="xs">
                                        <p><b>Vize</b> - Klient si uvědomuje potřebu
                                            a přínos výstavby nájemního
                                            bydlení, mapuje podmínky a
                                            možnosti výstavby. Fáze je
                                            zakončena zpracováním
                                            jednoduchého záměru projektu.</p>
                                        <p><b>Předprojektová příprava</b> - Klient
                                            podrobně hodnotí finanční,
                                            technické, kapacitní a další nároky
                                            projektu. Probíhá shromažďování
                                            potřebných podkladů a průzkumů
                                            pro zahájení projektových prací.
                                            Fáze je zakončena zpracováním
                                            studie proveditelnosti a jejím
                                            schválením v řídícím orgánu
                                            klienta (zastupitelstvu atd.).
                                        </p>
                                        <p><b>Projektová práce</b> - Klient zadává
                                            projektové práce na přípravě
                                            dokumentací pro územní řízení a
                                            pro stavební povolení. Fáze
                                            zahrnuje získání územního
                                            rozhodnutí, podání žádosti o
                                            stavební povolení a je zakončena
                                            usnesením zastupitelstva o
                                            pokračování v realizaci projektu.</p>
                                        <p><b>Stavební povolení - příprava na realizaci</b> - Fáze je zahájena
                                            získáním pravomocného
                                            stavebního povolení. Klient zadává
                                            zpracování dokumentace pro výběr
                                            zhotovitele stavby, zajistí výběr
                                            zhotovitele. Fáze je ukončena
                                            předáním staveniště.</p>

                                        <p><b>Realizace a provoz budovy</b> - Fáze
                                            zahrnuje realizaci stavby,
                                            kolaudaci stavy a zahájení provozu
                                            objektu. Z pohledu veřejných
                                            financí je ukončena koncem
                                            udržitelnosti.</p>

                                        <p slot="label">Fáze projektu</p>
                                    </GovAccordionItem>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
